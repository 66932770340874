import React, { FC } from 'react';

import FormGroup, { IPropsText as IFormGroupTextProps } from '../components/FormGroup';
import { TreeSelect as AntTreeSelect } from 'antd';
import { TreeSelectProps } from 'antd/es/tree-select';

interface ISelectProps {
  name: string;
  valid?: boolean;
  onChange?: any;
  value?: any;
}

export type IProps = IFormGroupTextProps & ISelectProps & TreeSelectProps;

const TreeSelect: FC<IProps> = ({
  label,
  tooltip,
  description,
  name,
  onChange,
  valid,
  value,
  ...props
}) => {
  const handleChange = (id: string) => {
    onChange({ target: { name, value: id } });
  };

  return (
    <FormGroup label={label} description={description} tooltip={tooltip} valid={valid}>
      <AntTreeSelect
        value={value}
        style={{ width: '100%', height: '44px' }}
        onChange={handleChange}
        {...props}
      />
    </FormGroup>
  );
};

export default TreeSelect;
