/**
 * Сервис для работы с пользователями
 */

import axios from 'axios';
import { IId } from '../models/types';

export interface IFilterParams {
  mapId?: IId;
  fts?: string;
  scope?: string;
}

const url = '/user';

const PositionsService = {
  getUsers: (params: IFilterParams = {}) => axios.get(`/v0/user/findUser`, { params })
};
export default PositionsService;
