/**
 * Сервис для работы с пользователями
 */

import axios from 'axios';

import { IId, ITrackInfo } from '../models/types';
import { pageSize } from '../constants/pagination';
import webSsoConstants from '../constants/webSsoConstants';

export interface IUpdateOrCreateBody {
  id: IId;
  tenantId?: IId;
  name: string;
  lastName: string;
  secondName: string;
  phone: string;
  email: string;
  birthday: string | null;
  authorizationData: {
    login: string | null;
    password: string | null;
    hasSsoAuth: boolean;
    changePass: boolean;
  };
  workInfo: {
    departmentId: IId | undefined;
    postId: IId;
    tabularNumber: string;
    protections: IId[];
  };
  settings: {
    role: string;
    planColor: string;
    immobilizedTimeMinutes: number;
    workshiftDurationHours: number;
    workshiftBreakMinutes: number;
  };
  trackerInfo?: {
    deviceId: number;
    deviceCharge: string;
  };
  photoContent?: string;
  photoContentType?: string;
  trackInfo?: ITrackInfo;
  charge?: {
    precent: number;
    isCharging: boolean;
    time: string;
    volt: any;
  };
}

export interface IGetAllParams {
  fullTextSearch?: string;
  departmentId?: string;
  includePreview?: boolean;
  pageIndex?: number;
  pageSize?: number;
}

export interface IGetBrigadeAllParams {
  fullTextSearch?: string;
  includeOther?: boolean;
  pageIndex?: number;
  pageSize?: number;
}

export interface IBody {
  username: string;
  password: string;
}

export interface IUserBody {
  id: IId;
  lastname: string;
  name: string;
  secondname: string;
  birthday: string;
  username: string;
  brigadeId: number;
  lead: string;
  title: string;
  tabularNumber: string;
  mapColor: string;
  protectionsList?: [];
  descr: string;
  avatar?: File;
}

export interface IBrigadeBody {
  id: IId;
  name: string;
}
export interface IDepartmentBody {
  id: IId;
  name: string;
  parentId?: IId;
  employees?: [
    {
      userId: IId;
      fio: string;
      role: string;
    }
  ];
}

export interface IRoleBody {
  id: IId;
  name: string;
  friendlyName: string;
}

export interface ISectreeACL {
  uid: IId;
  roleId: IId;
  action: string;
  result: string;
}

export interface IFilterParams {
  fullTextSearch?: string;
  pageSize?: number;
  includePreview?: boolean;
  includeOther?: boolean;
}

export interface IShortUsersParams {
  fts?: string;
}

export interface ILoginParams {
  name?: string;
  lastName?: string;
  secondName?: string;
}

export interface IGetByTokenParams {
  preview?: boolean;
}

export interface ISecurableTree {
  uid?: IId;
  parent: IId;
  type: string;
  systemName: string;
  friendlyName: string;
  hash?: string;
  clientVersion?: string;
}

export interface IUpdateOrCreateBrigadeBody {
  id: IId;
  name: string;
  leadId: IId;
  employees: Array<{
    userId: IId;
    fio: string;
  }>;
}

const url = '/user';

const UsersService = {
  getToken: (code: string) =>
    axios.get(`${url}/sso_callback`, {
      params: { code, redirect_uri: webSsoConstants.redirectUri }
    }),
  getRefreshedToken: (refreshToken: string | null) =>
    axios.post(`v0/auth/refresh_token?refreshToken=${refreshToken}`),
  get: (userName: string) => axios.get(`${url}/info/${userName}`, { params: { preview: true } }),
  getUserEmployees: (userId: IId) => axios.get(`/v0/user/info/userId=${userId}`),
  getEmployees: (id: IId) => axios.get(`/v0/employees/${id}`),

  getAll: (params: IGetAllParams) =>
    axios.get(`${url}/infoAll`, { params: { pageSize, ...params } }),
  getAllEmployees: (params: IGetAllParams) =>
    axios.get(`/v0/employees`, { params: { pageSize, ...params } }),
  getAllShort: (params: IShortUsersParams = {}) => axios.get(`${url}/infoAllShort`, { params }),
  getByToken: (params: IGetByTokenParams = {}) => axios.get(`${url}/info`, { params }),
  getRoles: () => axios.get(`${url}/roles`),
  getPosts: () => axios.get(`/v0/posts`),
  getRole: (id: IId) => axios.get(`/role/${id}`),
  getBrigade: (id: IId) => axios.get(`${url}/brigades/id=${id}`),
  getDepartment: (id: IId) => axios.get(`/v0/departments/${id}`),
  getBrigadeAll: (params: IGetBrigadeAllParams = {}) =>
    axios.get(`${url}/brigades`, { params: { pageSize, ...params } }),
  getDepartmentsAll: () => axios.get(`/v0/departments`),
  getLogin: (params: ILoginParams = {}) => axios.get(`${url}/sampleLogin`, { params }),
  updateOrCreate: (body: IUserBody) => axios.post('/v0/user', body),
  updateOrCreateEmployees: (body: IUpdateOrCreateBody) => axios.post('/v0/employees', body),
  updateOrCreateAvatar: (formData: FormData) => axios.post(`${url}/photo`, formData),
  updateOrCreateBrigade: (body: IBrigadeBody) => axios.post(`${url}/brigades`, body),
  updateOrCreateDepartment: (body: IDepartmentBody) => axios.post('/v0/departments', body),
  updateOrCreateRole: (body: IRoleBody) => axios.post(`/role`, body),
  updateOrCreateACL: (body: ISectreeACL[]) => axios.post(`${url}/sectree/acl`, body),
  delete: (id: number) => axios.delete(`${url}/info/userId=${id}`),
  deleteEmployees: (id: IId) => axios.delete(`/v0/employees/${id}`),
  deleteRole: (id: IId) => axios.delete(`/role`, { params: { id } }),
  deleteBrigade: (id: IId) => axios.delete(`${url}/brigades?brigadeId=${id}`),
  deleteDepartment: (id: IId) => axios.delete('/v0/departments/' + id),
  login: (body: IBody) => axios.post(`v0/auth/login`, body),
  getSecurableTreeVersion: (root: IId) => axios.get(`${url}/sectree/version`, { params: { root } }),
  getSecurables: (root: IId) => axios.get(`${url}/sectree/right?root=${root}`),
  getSecTreeHierarchy: () => axios.get(`${url}/sectree/hierarchy`),
  createSecurableTree: (root: IId, body: ISecurableTree[]) =>
    axios.post(`${url}/sectree?root=${root}`, body),
  getNodeACL: (root: IId, roleId: IId) =>
    axios.get(`${url}/sectree/nodeacl`, { params: { root, roleId } })
};

export default UsersService;
