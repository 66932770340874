import React, { FC, ReactNode } from 'react';
import moment from 'moment';
import { Form as FinalForm, FormRenderProps } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { MtsButton } from '@mts-ds/components-react';
import { ButtonColors } from '@mts-ds/components/dist/types/components/button/enums';

import { IUpdateOrCreateBody } from '../../../../services/UsersService';
import { required, phone, email, size, validCharacters } from '../../../../utils/validation';
import { getTimeFromMinutes, getMinutesFromTime } from '../../../../utils/convertTime';
import { hoursStep, minutesStep } from '../../../../constants/timeSteps';
import useRequestHandler from '../../../../hooks/useRequestHandler';

import ContentForm from '../../../../components_/Content/ContentForm';
import FormBase from '../../../../components_/Form';
import FormGroup from '../../../../components_/Form/FormGroup';
import FormRow from '../../../../components_/Form/FormRow';
import FormCol from '../../../../components_/Form/FormCol';
import FormDoubleField from '../../../../components_/Form/FormDoubleField';
import FormInput from '../../../../components_/Form/FormInput';
import FormDropdown from '../../../../components_/Form/FormDropdown';
import FormColorPicker from '../../../../components_/Form/FormColorPicker';
import FormButton from '../../../../components_/Form/FormButton';

import { IProps as IFormProps } from './';
import FormController from './FormController';
import FormDatePicker from '../../../../components_/Form/FormDatePicker';
import FormCheckbox from '../../../../components_/Form/FormCheckbox';
import { IDepartments, IId, IUserEmployees } from '../../../../models/types';
// import FormDropdownTree from '../../../../components_/Form/FormTree';
import FormAntTreeSelect from '../../../../components_/Form/FormAntTreeSelect';

const useStyles = makeStyles(({ spacing }) => ({
  color: {
    marginTop: spacing(2)
  },
  buttons: {
    '& > *:not(:last-child)': {
      marginRight: spacing(4)
    }
  }
}));

type IValues = IUpdateOrCreateBody & {
  immobilizedHours: number;
  immobilizedMinutes: number;
  breakMinutes: number;
  breakHours: number;
  protections: IId[];
};

type IRender = (props: FormRenderProps<IValues>) => ReactNode;

interface IProps {
  data: IFormProps['data'];
  item: IUserEmployees | null;
  isCreate: boolean;
  isEdit: boolean;
  onSubmitAction: any;
  onCancel: any;
}

const defaultValues: IValues = {
  id: '',
  name: '',
  lastName: '',
  secondName: '',
  phone: '',
  email: '',
  birthday: null,
  authorizationData: {
    login: null,
    password: '',
    hasSsoAuth: false,
    changePass: false
  },
  workInfo: {
    departmentId: undefined,
    postId: '',
    tabularNumber: '',
    protections: []
  },
  settings: {
    role: '',
    planColor: '#00c20d',
    immobilizedTimeMinutes: 0,
    workshiftDurationHours: 0,
    workshiftBreakMinutes: 0
  },
  immobilizedHours: 0,
  immobilizedMinutes: 0,
  breakMinutes: 0,
  breakHours: 0,
  protections: []
};

const Form: FC<IProps> = ({ data, item, isCreate, isEdit, onSubmitAction, onCancel }) => {
  const { departments, protections, roles, posts } = data;

  const classes = useStyles();
  const requestHandler = useRequestHandler();

  const createImmobilizedTime = (minutes: number) => {
    const [immobilizedHours, immobilizedMinutes] = getTimeFromMinutes(minutes);

    return { immobilizedHours, immobilizedMinutes };
  };

  const createBreakTime = (minutes: number) => {
    const [breakHours, breakMinutes] = getTimeFromMinutes(minutes);

    return { breakHours, breakMinutes };
  };

  const initialValues: IValues = {
    ...defaultValues,
    ...(isEdit &&
      item && {
        ...item,
        birthday: item.birthday && moment(item.birthday).format('YYYY-MM-DD'),
        workInfo: item.workInfo && {
          protections: item.workInfo.protections,
          tabularNumber: item.workInfo.tabularNumber,
          departmentId: item.workInfo.department && item.workInfo.department.id,
          postId: item.workInfo.post && item.workInfo.post.id
        },
        ...{ protections: item.workInfo && item.workInfo.protections },
        ...createImmobilizedTime(item.settings.immobilizedTimeMinutes),
        ...createBreakTime(item.settings.workshiftBreakMinutes)
      }),
    ...(isCreate && {
      authorizationData: {
        login: null,
        password: null,
        hasSsoAuth: false,
        changePass: true
      }
    })
  };

  const protectionOptions = protections.map(({ id, name }) => ({ id, name }));
  const roleOptions = roles.map(({ name, friendlyName }) => ({ id: name, name: friendlyName }));

  const submitButtonTitle = isEdit ? 'Сохранить' : 'Добавить';

  const onSubmit = async (values: IValues) => {
    const body = {
      ...values,
      birthday: values.birthday
        ? moment(values.birthday)
            .startOf('day')
            .format()
        : null,
      workInfo: values.workInfo && {
        ...values.workInfo,
        protections: values.protections,
        departmentId: values.workInfo.departmentId ? values.workInfo.departmentId : null
      },
      settings: {
        ...values.settings,
        immobilizedTimeMinutes:
          getMinutesFromTime(values.immobilizedHours, values.immobilizedMinutes) || null,
        workshiftBreakMinutes: getMinutesFromTime(values.breakHours, values.breakMinutes) || null,
        workshiftDurationHours: values.settings.workshiftDurationHours || null
      },
      authorizationData:
        values.authorizationData.hasSsoAuth || !values.authorizationData.changePass
          ? {
              ...values.authorizationData,
              password: null
            }
          : {
              ...values.authorizationData
            }
    };
    const error = await onSubmitAction(body);

    requestHandler({
      error,
      entity: 'user',
      onSuccess: onCancel,
      isCreate,
      isEdit
    });
  };

  interface IRenamedObject {
    value: string;
    title: string;
    children?: IRenamedObject[];
  }
  function renameProperties(objects: IDepartments[]): IRenamedObject[] {
    return objects.map(obj => {
      const { id, name, children } = obj;
      return {
        value: id,
        title: name,
        children: children ? renameProperties(children) : undefined
      };
    });
  }

  const render: IRender = ({ handleSubmit, values }) => {
    return (
      <FormBase style={{ height: '100%' }} onSubmit={handleSubmit}>
        <>
          <ContentForm>
            <Grid>
              <FormGroup title="Персональная информация" size="m">
                <FormRow>
                  <FormCol>
                    <FormInput
                      name="lastName"
                      label="Фамилия"
                      placeholder="Введите фамилию или псевдоним"
                      useFieldConfig={{ validate: value => required(value) || size(value, 35) }}
                    />
                  </FormCol>
                  <FormCol>
                    <FormInput
                      name="name"
                      label="Имя (необязательно)"
                      placeholder="Введите имя"
                      useFieldConfig={{ validate: value => size(value, 35) }}
                    />
                  </FormCol>
                  <FormCol>
                    <FormInput
                      name="secondName"
                      label="Отчество (необязательно)"
                      placeholder="Введите отчество"
                      useFieldConfig={{ validate: value => size(value, 35) }}
                    />
                  </FormCol>
                  <FormCol>
                    <FormDatePicker
                      name="birthday"
                      label="Дата рождения (необязательно)"
                      maxDate={new Date()}
                    />
                    {/*<FormBaseInput*/}
                    {/*  name="birthday"*/}
                    {/*  label="Дата рождения (необязательно)"*/}
                    {/*  type="date"*/}
                    {/*/>*/}
                  </FormCol>
                </FormRow>
                <FormRow>
                  {/*<FormCol>*/}
                  {/*  <FormInput name="username" label="Логин" placeholder="Введите логин" disabled />*/}
                  {/*</FormCol>*/}
                  <FormCol xs={3}>
                    <FormInput
                      name="phone"
                      label="Номер телефона (необязательно)"
                      placeholder="9669999999"
                      useFieldConfig={{ validate: value => phone(value) }}
                    />
                  </FormCol>
                  <FormCol xs={3}>
                    <FormInput
                      name="email"
                      label="Email (необязательно)"
                      placeholder="user@company.ru"
                      useFieldConfig={{ validate: email }}
                    />
                  </FormCol>
                  <FormCol />
                </FormRow>
              </FormGroup>
              <FormGroup title="Данные для авторизации" size="m">
                <FormRow>
                  <FormCol style={{ marginTop: '30px' }} alignItems="center" xs={3}>
                    <FormCheckbox
                      name="authorizationData.hasSsoAuth"
                      label="Организация использует SSO"
                    />
                  </FormCol>
                  <FormCol xs={3}>
                    <FormInput
                      name="authorizationData.login"
                      label="Логин (необязательно)"
                      placeholder="Введите логин"
                      useFieldConfig={{ validate: validCharacters }}
                    />
                  </FormCol>
                  <FormCol xs={3}>
                    <FormInput
                      name="authorizationData.password"
                      type="password"
                      label="Пароль (необязательно)"
                      disabled={values.authorizationData.hasSsoAuth}
                      placeholder="Введите пароль"
                    />
                    <FormCheckbox
                      name="authorizationData.changePass"
                      disabled={values.authorizationData.hasSsoAuth}
                      label="Сменить пароль"
                    />
                  </FormCol>
                </FormRow>
              </FormGroup>
              <FormGroup title="Рабочая информация" size="m">
                <FormRow>
                  <FormCol>
                    <FormAntTreeSelect
                      name="workInfo.departmentId"
                      placeholder="Выберите подразделение"
                      label="Подразделение (необязательно)"
                      value={values.workInfo.departmentId}
                      showSearch
                      treeNodeFilterProp={'title'}
                      treeData={renameProperties(departments)}
                    />
                    {/*<FormDropdownTree*/}
                    {/*  name="workInfo.departmentId"*/}
                    {/*  placeholder="Выберите подразделение"*/}
                    {/*  label="Подразделение (необязательно)"*/}
                    {/*  treeHierarchy={departments}*/}
                    {/*/>*/}
                  </FormCol>
                  <FormCol>
                    <FormDropdown
                      name="workInfo.postId"
                      label="Должность (необязательно)"
                      placeholder="Выберите должность"
                      options={posts}
                    />
                    {/*<FormInput*/}
                    {/*  name="workInfo.title"*/}
                    {/*  label="Должность (необязательно)"*/}
                    {/*  placeholder="Введите должность"*/}
                    {/*/>*/}
                  </FormCol>
                  <FormCol>
                    <FormInput
                      name="workInfo.tabularNumber"
                      label="Табельный номер (необязательно)"
                      placeholder="Введите табельный номер"
                      type="number"
                    />
                  </FormCol>
                  <FormCol>
                    <FormDropdown
                      name="protections"
                      label="Экипировка (необязательно)"
                      placeholder="Выберите экипировку"
                      multiple
                      options={protectionOptions}
                    />
                  </FormCol>
                </FormRow>
              </FormGroup>
              <FormGroup title="Настройки" size="m">
                <FormRow>
                  <FormCol>
                    <FormDropdown
                      name="settings.role"
                      label="Роль"
                      placeholder="Выберите роль"
                      options={roleOptions}
                      useFieldConfig={{ validate: required }}
                    />
                  </FormCol>
                  <FormCol>
                    <FormDoubleField
                      label="Обездвиженность (необязательно)"
                      separator="-"
                      left={
                        <FormDropdown
                          name="immobilizedHours"
                          placeholder="Часы"
                          options={hoursStep}
                        />
                      }
                      right={
                        <FormDropdown
                          name="immobilizedMinutes"
                          placeholder="Минуты"
                          options={minutesStep}
                        />
                      }
                    />
                  </FormCol>
                  <FormCol>
                    <FormDropdown
                      name="settings.workshiftDurationHours"
                      label="Длительность смены (необязательно)"
                      placeholder="Выберите длительность смены"
                      options={hoursStep}
                    />
                  </FormCol>
                  <FormCol>
                    <FormDoubleField
                      label="Длительность перерыва (необязательно)"
                      separator="-"
                      left={
                        <FormDropdown name="breakHours" placeholder="Часы" options={hoursStep} />
                      }
                      right={
                        <FormDropdown
                          name="breakMinutes"
                          placeholder="Минуты"
                          options={minutesStep}
                        />
                      }
                    />
                  </FormCol>
                </FormRow>
                <FormRow className={classes.color}>
                  <FormCol>
                    <FormColorPicker name="settings.planColor" label="Цвет сотрудника на карте" />
                  </FormCol>
                </FormRow>
              </FormGroup>
            </Grid>
            <Grid container justify="center" className={classes.buttons}>
              <MtsButton color={'negative' as ButtonColors} onClick={onCancel}>
                Отмена
              </MtsButton>
              <FormButton>{submitButtonTitle}</FormButton>
            </Grid>
          </ContentForm>
          <FormController isCreate={isCreate} />
        </>
      </FormBase>
    );
  };

  return <FinalForm initialValues={initialValues} onSubmit={onSubmit} render={render} />;
};

export default Form;
